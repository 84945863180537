$color_1: #3E7671;
$color_2: #131725;
$color_3: #333333;
$color_4: #333333;
$color_6: #3E7671;
$background-color_1: #e2e2e2;
$background-color_2: #3E7671;
$background-color_3: transparent;

.logo {
  max-width: 180px;
  height: 50px;
@media (max-width: 768.98px) {
  max-width: 120px !important;
  height: 35px;
}
}

.headerMain {
  position: fixed;
  width: 100vw;
  padding:18px 0px;
  transition: all ease-in-out .3s;
  z-index: 100;
  backdrop-filter: blur(6px);
  @media (max-width: 768.98px) {
    position: sticky;
    top: 0;
  }

  &.sticky {
    backdrop-filter: blur(10px);
    background-image: linear-gradient(126.85deg, #677e6c8a -36.13%, #9eaeca8a 98.05%);
    padding: 18px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .headerBtn{
      background: #fff !important;
      color: #0f8948 !important;
    }

    .siteLogo {
      img {
        width: 175px;

        @media (max-width: 1200px) {
          width: 90px;
        }
      }
    }

  }

  .navbar {
    padding: 0 !important;
  }

  // &.sticky .navItem{
  //   color: #0f8948 !important;
  //   &::before {
  //     content: '';
  //     background-color: #0f8948 !important;
  //   }

  //   &.active,
  //   &.hover {
  //     color: #0f8948;
  //     opacity: 1;

  //     &::before {
  //       opacity: 1;
  //     }
  //   }
  // }

  .siteLogo {
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;

    img {
      width: 100%;
      transition: all ease-in-out .3s;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      display: block;
      text-decoration: none;
    }
  }

  .navList {
    align-items: center;
  }

  .navItemWrap {
    margin-left: 30px;
    position: relative;

    .navItem {
      display: inline-block;
      color: #fff;
      opacity: 0.7;
      font-weight: 600;
      text-transform: capitalize;
      cursor: pointer;
      padding: 14px 0px;

      &::before {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #fff;
        opacity: 0;
      }

      &.active,
      &.hover {
        color: #fff;
        opacity: 1;

        &::before {
          opacity: 1;
        }
      }

      &.headerBtn {
        // border: 2px solid linear-gradient(to right, #0f8948 0%, #dde65a 100%);
        background: linear-gradient(to right, #0f8948 0%, #dde65a 100%);
        color: #fff;
        border-radius: 100px;
        text-align: center;
        padding: 10px 20px;
        // margin: 10px 0;
        transition: all 0.5s;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        &::before {
          opacity: 0;
        }

        &:hover {
          background: #fff;
          color: #215d42 !important;
          // border: 2px solid #215d42;
          // border-width: 2px;
          // border-style: solid;
          // border-color: linear-gradient(to right, #9966ff 0%, #ff99cc 100%);
          // color: var(--primary) !important;
        }
      }
    }
  }

  .megaMenuWrapper {
    position: absolute;
    top: 100%;
    left: -512px;
    width: 800px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1254901961);
    z-index: 9 !important;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    transition: all ease-in-out 0.5s;
    grid-gap: 30px;

    .menuWrap {
      width: calc(50% - 15px);
    }

    .itemLink {
      text-transform: capitalize;
      margin-bottom: 5px;
      font-weight: 500;
      cursor: pointer;
      font-size: 16px;
      transition: all ease-in-out .3s;
      color: #000;

      &::before {
        content: "\f061";
        font-family: "FontAwesome";
        margin-right: 5px;
      }

      &:hover,
      &.active {
        color: var(--secondary);
        margin-left: 5px;
      }
    }
  }
}

button.navbar-toggler {
  border: none;
  padding: 0;
  color: #000 !important;
  font-size: 25px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.mobileMenu {
  padding: 0;
  list-style: none;

  .navItem {
    font-weight: 500;
    color: #000;
    text-decoration: none;
    text-transform: capitalize;
    padding: 15px 30px 15px 10px;
    display: block;
    border-bottom: 1px solid #ddd;
    background-color: #fff;

    &.active {
      color: var(--secondary);
      background-color: var(--);
    }
  }

  .accordion-body {
    padding: 0;
    border-left: 2px solid var(--secondary);

    ul {
      list-style: none;
      padding: 0;

      .itemLink {
        font-weight: 500;
        display: block;
        color: #000;
        text-transform: capitalize;
        padding: 15px 10px;
        text-decoration: none;
        border-bottom: 1px solid #ddd;

        &::before {
          content: "\f061";
          font-family: "FontAwesome";
          margin-right: 5px;
        }
      }
    }

    .accordion-body {
      margin-left: 10px;
      background: #f5f5f5;
    }


  }

  .accordion-button {
    font-weight: 500;
    color: #000;
    text-decoration: none;
    text-transform: capitalize;
    padding: 15px 30px 15px 10px;
    display: block;
    border-bottom: 1px solid #ddd;
    position: relative;
    font-family: "Inter", sans-serif;
    border-radius: 0 !important;
    background-color: #fff;

    &::after {
      position: absolute;
      right: 5px;
      top: 15px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .accordion-item {
    border: none;
    border-radius: 0;
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
}