/******Universal Style Start*******/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=League+Spartan:wght@100..900&display=swap');


:root {
  --primary: #2b5d44;
  --secondary: #f18705;
  --tertiary: #51AFA7;
  --white: #ffffff;
  --text-color-dark: #333333;
  --bg-color1: #dce9e7;
  --bg-color2: #efefef;
}


html {
  // width: 100%;
  // height: 100%;
  // margin: 0;
  // padding: 0;
  // overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

a {
  transition: all ease-in-out .3s;

  &:hover {
    color: var(--secondary);
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: var(--text-color-dark);
  line-height: 1.5;
  overflow-x: hidden;

  @media (max-width: 1200px) {
    font-size: 15px;
  }

}

html,
body {
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
}

p {
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "League Spartan", sans-serif;
  // color: #fff;
}

img {
  max-width: 100%;
  height: auto;
}

.fontInter {
  font-family: "Inter", sans-serif;
}

// ================v-css===================

.customForm {
  .inputWrap {
    margin-bottom: 25px;
  }

  .form_input {
    width: 100%;
    padding: 12px 20px;
    background-color: #fff;
    color: #333333;
    border: 1px solid #bbbbbb;
    font-size: 16px;
    transition: all ease-in-out .3s;

    &:focus {
      outline: none;
      border-color: #000;
    }

    &.textAarea {
      min-height: 120px;
    }
  }
}

.contact_usInfo li {
  padding-bottom: 10px;
  list-style: none;
  display: flex;

  a,
  i {
    color: #2e8585;
    text-decoration: none;
  }
}

.portfolio_img_1 {
  width: 100%;
  max-width: 520px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.success_modal {
  padding: 30px;
  text-align: center;

  h3 {
    font-size: 28px;
    font-weight: 600;
    color: #3E7671;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 20px;
  }

  button {
    background-color: #3E7671;
    color: #fff;
    padding: 5px 40px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px 0 8px 0;
    transition: all 0.5s;

    &:hover {
      background-color: #efefef;
      color: #3E7671;
      border: 1px solid #3E7671;
      border-radius: 0 8px 0 8px;
    }
  }
}

.required {
  color: red;
  display: block;
}

.required_hide {
  color: blue;
  display: none;
}

// ========================================