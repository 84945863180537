// Override Bootstrap variables
@import './mixins.scss';

// Import Bootstrap SCSS file from react-bootstrap
@import '~bootstrap/scss/bootstrap';


/**************Custom Style Start**********/




/******Custom Utility CSS Start*******/


.text {
  &-20 {
    font-size: 20px;

    @media (max-width: 1400px) {
      font-size: 18px;
    }

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f618b; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9966ff; 
}


.title {

  &-sm {
    font-size: 20px;
    letter-spacing: .5px;


    @media (max-width: 1200px) {
      font-size: 18px;
    }

  }

  &-md {
    font-size: 25px;
    letter-spacing: .5px;

    @media (max-width: 1200px) {
      font-size: 22px;
    }

    @media (max-width: 767.98px) {
      font-size: 20px;
    }
  }

  &-xl {
    font-size: 42px;
    letter-spacing: .5px;

    @media (max-width: 1400px) {
      font-size: 40px;
    }

    @media (max-width: 1200px) {
      font-size: 36px;
    }

    @media (max-width: 767.98px) {
      font-size: 28px;
    }

    @media (max-width: 576.98px) {
      font-size: 26px;
    }
  }

  &-xxl {
    font-size: 60px;

    @media (max-width: 1400px) {
      font-size: 55px;
    }

    @media (max-width: 1200px) {
      font-size: 50px;
    }

    @media (max-width: 767.98px) {
      font-size: 45px;
    }
  }
}


.bg-gray {
  background-color: var(--bg-color1);
}

.bg-gray2 {
  background-color: var(--bg-color2);
}

.dBtn {
  font-size: 16px;
  padding: 12px 25px;
  border: none;
  font-weight: 600;
  letter-spacing: .5px;
  transition: all 0.5s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // min-width: 160px;
  text-decoration: none;

  &.hasShadow {
    box-shadow: 2px 2px 10px 0px #00000050;
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  &.btnPrimary {

    border: 1px solid var(--primary);
    background: var(--primary);
    color: #fff;
    // border-radius: 10px 0px 10px 0px;
    min-height: 56px;

    &:hover {
      background: #fff;
      color: var(--primary);
      border-radius: 0px 10px 0px 10px;
    }
  }

  &.btnSecondary {
    border: 1px solid var(--secondary);
    background: var(--secondary);
    color: #fff;
    // border-radius: 10px 0px 10px 0px;
    // min-height: 40px;

    &:hover {
      background: #fff;
      color: var(--secondary);
      border-radius: 0px 10px 0px 10px;
    }
  }

  &.btnBorder {
    background: transparent;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    border-radius: 10px 0px 10px 0px;
    min-height: 56px;

    &:hover {
      background: var(--secondary);
      color: #fff;
      border-radius: 0px 10px 0px 10px;
    }
  }

  &.btnText {
    background: transparent;
    padding: 0;
    min-width: auto;
    color: var(--secondary);

    &:hover {
      color: #000;
      letter-spacing: 1px;
    }
  }

  svg {
    font-size: 22px;
  }
}

.sectionPadding {
  padding: 100px 0px;

  @media (max-width: 1200px) {
    padding: 80px 0px;
  }
}

.sectionInfo {
  max-width: 800px;
  margin: 0 auto;
}

.section-title-sm {
  color: #2e8585;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  border-width: 0px 0px 2px 0px;
  border-style: dashed;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 20px;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
}

.cardType1 {
  @include cardType1;
}

.cardType2 {
  @include cardType2;
}

.cardType3 {
  @include cardType1;

}


.cardIcon-Type1 {
  @include cardIcon-Type1;
}

.cardIcon-Type2 {
  @include cardIcon-Type2;
}


.cardIndexNumber {
  position: absolute;
  font-size: 80px;
  font-weight: 800;
  opacity: .2;
  color: var(--primary);
  right: 10px;
  top: 5px;
  min-width: 60px;
  line-height: 1;
}

.cardHoverEffect {
  &:hover {
    background-color: var(--secondary);
    color: #fff;

    .icon {
      &::before {
        background-color: var(--bg-color1);
      }

      img {
        filter: invert(1);
      }
    }

    .cardIndexNumber {
      color: #f5f5f5;
    }
  }
}

.green_text {
  color: #2e8585;
  font-weight: 600;
}

.hasShadow {
  box-shadow: 0px 0px 10px 0px #00000025;
}

/******Custom Utility CSS End*******/


/*****Inner Page Banner Css Start*****/

.innerBanner {
  padding: 210px 0px 130px;
  // background-image: linear-gradient(180deg, #f5f5f5 0%, rgba(59, 137, 210, 0.4745098039) 100%);
  background-image: linear-gradient(45deg,
      rgba(56, 56, 56, 0.57),
      rgba(34, 36, 37, 0.517)), url(../../../public/images/inner-banner-3.jpg);
  border-radius: 0px 0px 80px 80px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: 25% 16%;
  box-shadow: 0px 0px 15px 10px #00000025;

  @media (max-width: 1400px) {
    border-radius: 0px 0px 100px 100px;
  }

  @media (max-width: 991.98px) {
    padding: 200px 0px 100px;
    border-radius: 0px 0px 50px 50px;
  }

  @media (max-width: 767.98px) {
    padding: 180px 0px 80px;
    border-radius: 0px 0px 30px 30px;
  }

  // &::before {
  //   content: "";
  //   background-image: url(./../Images/rocket.svg);
  //   width: 300px;
  //   height: 300px;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   position: absolute;
  //   right: -35px;
  //   bottom: -35px;
  //   background-position: right bottom;
  //   filter: invert(1);

  //   @media (max-width: 991.98px) {
  //     width: 200px;
  //     height: 200px;
  //   }
  // }
}
.inner_banner_p{
  color: #fff;
  font-size: 18px !important;
}

/***********Inner Page Banner Css Start************/

.postCard {
  @include postCard
}

.slick-slide {
  div {
    &:focus-visible {
      outline: none;
    }
  }
}

/*********************/

.fullWidth_hero_img {
  background-image: url(./../Images/homepage/bg-01.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000096;
    opacity: .6;
  }
}

.hero_banner {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card_image {
  width: 100%;
  height: 350px;
  max-width: 300px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 10px;
}

.size_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    color: #404349;
    border-radius: 3px;
    cursor: pointer;
    border: 0.5px solid rgb(197, 196, 196);

    &:hover {
      border: 0.5px solid rgb(57, 57, 57);
      color: #000;
    }
  }

}

.shop_card {
  max-width: 300px;
  // @media (max-width: 767.98px) {
  //   max-width: 170px;
  // }
}

.category_name {
  font-size: .85em;
  margin-bottom: .5em;
  line-height: 1.3;
  color: #282c34;
  opacity: .6;
  margin-bottom: 5px;
}

.card_title {
  font-size: 1em;
  color: #000;
  margin-bottom: 5px;

}

.price {
  font-size: .9em;
  font-weight: 600;
  color: #404349;
  opacity: .6;
}

/***************/
.faqWrapper {
  grid-gap: 15px;
  display: grid;

  .faqWrap {
    position: relative;
    box-shadow: 0px 0px 3px 2px #00000015;
    border-radius: 5px;
    overflow: hidden;

    .title {
      padding: 15px 40px 15px 15px;
      cursor: pointer;
      transition: all ease-in-out .3s;
      position: relative;
      background-color: #fff;

      &:hover,
      &.active {
        background-color: var(--bg-color2);
      }

      .faqIcon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        color: var(--secondary);
      }
    }

    .description {
      padding: 15px;
    }
  }
}


/******Contact-us page css start*******/

.mapSection {
  line-height: 0;
}

/******Contact-us page css end*******/

/******Portfolio page css start*******/
.portfolioWrapper {
  section {
    &:nth-child(even) {
      background-color: var(--bg-color2);

      .row {
        flex-direction: row-reverse;
      }
    }

    img {
      box-shadow: 0px 0px 10px 2px #00000025;
    }
  }
}

/******Portfolio page css end*******/
.slide_img{
  width: 100%;
  max-height: 800px;
  object-fit:cover;
  object-position: center;

}
.item_center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-caption{
  position: absolute; 
  right: 15%;
  bottom: 15rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  @media (max-width:1200px) {
    bottom: 12rem;
  }
  @media (max-width: 991.98px) {
    bottom: 10rem;
  }

  @media (max-width:767.98px) {
    bottom: 7rem;
  }
  @media (max-width:580px) {
    bottom: 4rem;
  }
  @media (max-width:380px) {
    bottom: 30px !important;
  }
}

.shopbtn{
  @media (max-width: 767.98px) {
    padding: 5px 12px !important;
    width: auto;
  }
}