@mixin cardType1{
  background-color: var(--bg-color2);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0C00000F;
  transition: all 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 30px 30px 30px;
  position: relative;
  cursor: pointer;
  &:hover{
    background-color: #fff;
  }
}
@mixin cardType2{
    background-color: var(--bg-color2);
    transition: all 0.3s, border 0.3s, 
    border-radius 0.3s, 
    box-shadow 0.3s;
    padding: 50px 30px 30px 30px;
    position: relative;
    cursor: pointer;
}




@mixin cardIcon-Type1{
    position: relative;
    margin-bottom: 30px;
    &::before {
        background: var(--primary);
        position: absolute;
        content: "";
        border-radius: 50px;
        width: 80px;
        height: 80px;
        left: 16px;
        top: 4px;
        z-index: 0;
        opacity: 0.2;
    }
    img {
        max-width: 70px;
        position: relative;
    }
}

@mixin cardIcon-Type2{
  position: relative;
  margin-bottom: 30px;
  img {
      max-width: 70px;
      position: relative;
  }
}




/*******************/
@mixin cardImage {
    width: 100%;
    padding-top: calc(100% /(16/9));
    background: #ddd;
    background-image: url(./../Images/dummyImage.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: block;
    &:hover{
        img{
            transform: translate(-50%, -50%) scale(1.05);
        }
        &::before{
            opacity: .4; 
        }
    }
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all ease-in-out .3s;
    }
    &::before{
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        background: #000;
        opacity: .2;
        top: 0;
        left: 0;
        z-index: 1;
        transition: all ease-in-out .3s;
    }
}


@mixin postCard{
    background: #fff;
    height: 100%;
    .postImageWrap {
      @include cardImage;
    }
    .post{
      &-details{
        text-align: left;
        padding: 20px 15px;
      }
      &-title{
        a{
            text-decoration: none;
            color: #000;
            &:hover{
                color: var(--secondary);
            }
        }
      }
      &-info{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      &-date{
        opacity: .5;
        font-weight: 600;
      }
    }
}