
.siteFooter{
    color: #f2f2f2;
    .footerTopWrap{
        padding: 80px 0px;
        background-color: #282F3B;
        font-size: 16px;
        @media (max-width: 767.98px) {
            padding: 40px 0;
        }
        h2{
            position: relative;
            color: #fff;
            &::after{
                content: "";
                width: 40px;
                height: 3px;
                background: var(--secondary);
                position: absolute;
                top: 100%;
                left: 0;
            
            }
        }
        .footerRow{
            grid-gap: 50px 0px;
        }
        .footerList{
            list-style: none;
            padding: 0;
            font-size: 15px;
            &.linkList{
                li{
                    a{
                        &::before{
                            content: "\f061";
                            font-family: 'FontAwesome';
                            margin-right: 5px;
                        }
                    }
                }
            }
            &.contactInfo{
                li{
                    position: relative;
                    padding-left: 30px;
                    i{
                        position: absolute;
                        top: 5px;
                        left: 0;
                    }
                }
            }
            li{
                margin-bottom: 12px;
                i{
                    margin-right: 5px;
                }
                a{
                    color: #f5f5f5;
                    text-decoration: none;
                    font-weight: 500;
                    &:hover, &.active{
                        color: var(--secondary);
                        padding-left: 3px;
                    }
                }


            }
        }
    }
    .footerCopyright{
        background-color: #1F2530;
        color: #fff;
        font-size: 14px;
        text-align: center;
        padding: 20px 15px;
        font-weight: 500;
        letter-spacing: .5px;
        @media (max-width: 767.98px) {
            font-size: 12px;
        }
    }
}

.socialMediaList{
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 25px 0px 0px;
    border-top: 1px solid #f5f5f52f;
    padding: 25px 0px 0px;
    grid-gap: 10px;
    li{
        a{
            width: 100%;
            color: #fff;
            text-align: center;
            font-size: 22px;
            display: inline-block;
            line-height: 1;
            width: 45px;
            height: 45px;
            background: var(--secondary);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover{
                background-color: var(--primary);
            }
        }

          
    }
}

